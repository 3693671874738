<template lang="pug">
    modal.language-pop-up
        template(v-slot:body)
            div.language-list-block(v-if="languageList")
                div.language-row(:class="{'selected': language.code == userLanguage }" v-for="language in languageList" @click="updateUserLanguage(language)")
                    img.icon.icon-24(:src="language.icon")
                    p.text-averta-semibold-20.label {{language.label}}
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    name: "language-pop-up",
    computed: {
        languageList() {
            let toReturn = []
            let languageList = this.$store.getters['language/getLanguageList'];
            if (languageList) {
                Object.keys(languageList).forEach((key) => {
                    let language = languageList[key]
                    toReturn.push(language)
                });
            }
            return toReturn
        },
        userLanguage() {
            return this.$store.getters['language/getUserLanguage']
        }
    },
    methods: {
        updateUserLanguage(language) {
            this.$emit('close',true)
            this.reset()
            this.$store.dispatch('language/updateUserLanguage', language.code).then(response => {

            })
        },
        reset() {
            this.$store.commit('search/resetWrapper')
        },
    },
    components: {
        Modal: () => import ("skillbase-component/src/components/common/Modal.vue")
    },
    mounted() {
        this.$store.dispatch('language/fetchLanguageList')
    }
});
</script>

<style lang="scss">
.language-pop-up {
    .popup {
        padding: 56px;
        height: fit-content;
        width: fit-content;
    }

    .language-list-block {
        .language-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 12.5px 28px;
            width: 84px;
            margin-bottom: 28px;
            cursor: pointer;

            .label {
                margin-left: 10.5px;
            }

            &.selected, &:hover {
                box-shadow: 0 0 16px var(--shadow-color);
                border-radius: 4px;
            }

            img {
                height: 18px;
                width: 18px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>