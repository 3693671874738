<template lang="pug">
    .header--actions
        dropdown-toggle.dropdown-toggle(
            v-if="$route.meta.layout == 'user'"
        )
            template(v-slot:dropdown-toggle-button)
                button.header--actions--button.create-button.button.btn-primary-rounded {{ $t('create') }}
                    img(src="@/assets/icon/create-icon.svg")
            template(v-slot:dropdown-toggle-menu)
                .dropdown-menu
                    .header--actions--module-row.section(
                        v-for="(module, index) in modulesToShow",
                        :key="'create-actions-' + index",
                        v-if="modules && module.createRoute",
                        @click="loadModal(module); $eventBus.$emit('DropdownToggle:close'); $store.commit('search/resetWrapper')"
                    )
                        img.icon.icon-24(:src="module.icon")
                        p.text-averta-regular-20 {{ $t(module.creationLabel) }}
                    .header--actions--module-row.section(
                        v-for="module in erpModules"
                        :key="'create-erp-actions-' + module.label"
                        @click="$eventBus.$emit('DropdownToggle:close');$store.commit('search/resetWrapper');module.go()"
                    )
                        img.icon.icon-24(:src="module.icon")
                        p.text-averta-regular-20 {{ module.label }}

        button.header--actions--button.bell-button.button.outline-primary-circle-icon(
            @click="showNotification",
            :class="[$route.meta.layout]", v-if="$route.meta.layout == 'user'"
        )
            img(src="@/assets/icon/Bell.svg")
            span.badge(v-if="notificationCounter >= 1")

        dropdown-toggle.dropdown-toggle(:menuWidth="178")
            template(v-slot:dropdown-toggle-button)
                button.header--actions--button.profil-button.button.rounded(v-if="user")
                    img.img-profile(
                        :src="getUserImageUrl(user.username)",
                        @error="replaceByDefault",
                        v-if="showUserImg"
                    )
                    .user-profile(v-else  :style="'background-color:' + (user.extraproperties && user.extraproperties.color ? user.extraproperties.color: '#FBC0C0') ")
                        p.title-3(v-if="!hasChinese(user.first_name, user.last_name)") {{ user.first_name.substr(0, 1) + user.last_name.substr(0, 1) }}
                        p.title-3(v-else) {{ user.first_name }}
            template(v-slot:dropdown-toggle-menu)
                .dropdown-menu
                    .section(@click="goTo('/annuaire/' + username)")
                        img.icon.icon-24(src="@/assets/icon/profil-icon.svg")
                        p.link.text-averta-regular-20 {{ $t('myprofil') }}
                    .section(@click="showLanguagePopUp")
                        img.icon.icon-24(src="@/assets/icon/language.svg")
                        p.link.text-averta-regular-20 {{ $t('language') }}
                    .section(v-if="$store.getters['acl/isModerator'] && $route.meta.layout != 'moderator'", @click="goTo('/moderator')")
                        img.icon.icon-24(src="@/assets/icon/moderateur-interface.svg")
                        p.link.text-averta-regular-20 {{ $t('moderatorInterface') }}
                    .section(v-if="isAdmin && $route.meta.layout != 'admin'", @click="goTo('/admin/kpi')")
                        img.icon.icon-24(src="@/assets/icon/admin-interface.svg")
                        p.link.text-averta-regular-20 {{ $t('adminInterface') }}
                    .section(v-if="$route.meta.layout != 'user'", @click="goTo('/home')")
                        img.icon.icon-24(src="@/assets/icon/user-interface.svg")
                        p.link.text-averta-regular-20 {{ $t('userInterface') }}
                    .section(@click="logout()" v-if="showLogout")
                        img.icon.icon-24(src="@/assets/icon/logout.svg")
                        p.link.text-averta-regular-20 {{ $t('logout') }}
</template>

<script lang="ts">
import Vue from "vue";
import UserService from "skillbase-component/src/components/manage-user/services/UserService.js";
import Modules from "@/applicationSettings/Modules.js";
import LanguagePopUp from "./LanguagePopUp.vue";
import TpaService from "skillbase-component/src/components/skillbase-oauth2-server/services/TpaService";

export default Vue.extend({
    components: {
        DropdownToggle: () =>
            import("skillbase-component/src/components/common/DropdownToggle")
    },

    methods: {
        showNotification() {
            this.showNotifications = !this.showNotifications;
            if (this.showNotifications) {
                this.$store.dispatch('activity/notification/seeNotifications')
            }
            this.$emit("showNotification", this.showNotifications);
        },
        hasChinese(firstName, lastName) {
            let hasChinese = false;
            const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;

            if (REGEX_CHINESE.test(firstName) || REGEX_CHINESE.test(lastName)) {
                hasChinese = true
            }

            return hasChinese;
        },
        goTo(url) {
            this.$store.commit("search/resetWrapper");
            this.$router.push(url)
        },
        loadModal(module) {
            if (module.modals && module.modals.create) {
                this.$eventBus.$emit(
                    "ModalLauncher:load",
                    module.modals.create
                );
            } else {
                this.$router.push(module.createRoute);
            }
        },
        getUserImageUrl(username) {
            return UserService.getUserImageUrl(username) + "?" + Date.now();
        },
        showLanguagePopUp() {
            this.$eventBus.$emit("ModalLauncher:load", LanguagePopUp);
        },
        replaceByDefault(e) {
            this.showUserImg = false;
            this.$forceUpdate();
        },
        logout() {
            this.$store.dispatch("logout");
            this.$router.push("/login");
        }
    },

    data() {
        return {
            modules: null,
            showNotifications: false,
            showUserImg: true,
            showLogout: false
        };
    },

    computed: {
        user() {
            if (this.$store.getters.user && this.$store.getters.user.username) {
                let username = this.$store.getters.user.username
                return this.$store.getters['user-profile/getUser'](username)
            } else {
                return false
            }
        },

        username() {
            return this.user ? this.$store.getters.user.username : "";
        },

        isAdmin() {
            return this.$store.getters['acl/isAdmin'];
        },

        notificationCounter() {
            return this.$store.getters['activity/notification/unseenNotifications']
        },

        erpModules() {
            const toReturn = []
            for (const type of this.erpTypes) {
                if (this.$store.getters['erp/metas'](type)) {
                    toReturn.push({
                        icon: this.$store.getters['erp/metas'](type).icon,
                        label: this.$store.getters['erp/metas'](type).label,
                        go: () => {
                            this.$router.push({path: '/erp/create', query: {type: type}})
                        }
                    })
                }
            }
            return toReturn
        },
        erpTypes() {
            return this.$store.getters['erp/erpTypes']
        },

        modulesToShow() {
            let modulesToAdd = {}
            if (this.modules) {
                Object.keys(this.modules).forEach(key => {
                    let canAdd = true
                    if (
                        key == 'group' &&
                        process.env.VUE_APP_USER_CANT_CREATE_GROUP == 'true' &&
                        this.isAdmin == false &&
                        this.$store.getters['acl/isModerator'] == false
                    ) {
                        canAdd = false
                    }

                    if (canAdd == true) {
                        modulesToAdd[key] = this.modules[key]
                    }
                })
            }
            return modulesToAdd
        }
    },

    watch: {
        user: {
            deep: true,
            immediate: true,
            handler(newvalue, oldValue) {
                if(newvalue) {
                    this.getUserImageUrl(this.user.username);
                    this.$store.dispatch('user-profile/fetchUser', this.user.username)
                }
            }
        }
    },

    mounted() {
        TpaService.getTypeOfTPA()
            .then((type) => {
                this.showLogout = type ? false : true;
            })
            .catch(() => {
                this.showLogout = true
            })

        Modules.getModules().then((modules) => {
            this.modules = modules
        });
        this.$store.dispatch('activity/notification/countUnseenNotifications')
        this.$eventBus.$on("UserProfile.imgUpdated", (response) => {
            if (response) {
                this.showUserImg = true;
                this.getUserImageUrl(this.username);
            }
        });

        this.$eventBus.$on('Header:hideNotification', () => {
            this.showNotifications = false
        });
    },
});
</script>

<style lang="scss" scoped>
.header--actions {

    display: inline-flex;
    align-items: flex-end;

    &--button {
        position: relative;
        margin-right: 25px;

        &:focus {
            outline: 0;
        }

        &.profil-button {
            border: none;
            width: 35px;
            height: 35px;
            margin-top: 0px;

            .img-profile {
                width: 35px;
                height: 35px;
                top: 0;
                right: 0;
                position: absolute;
                border-radius: 100%;

                &:hover {
                    opacity: 0.7;
                }
            }

            .user-profile {
                width: 35px;
                height: 35px;
                position: absolute;
                border-radius: 100vh;
                top: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;

                p {
                    text-transform: uppercase;
                    color: white;
                    text-align: center;
                }
            }

            p {
                color: #fff;
            }
        }
    }

    &--module-row {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
            width: 18px;
            height: 18px;
        }

        p {
            padding-left: 24px;
            width: fit-content;
            max-width: 128px;
            min-width: 75px;
        }

        &:hover {
            img {
                filter: var(--svg-filter-primary-color);
            }

            p {
                color: var(--primary-color);
            }
        }
    }

    .dropdown-toggle {
        height: fit-content;

        .dropdown-menu {
            color: var(--text-color);
            background-color: white;
            padding: 18px;

            .link {
                display: block;
                text-decoration: none;
            }

            .section {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 28px;
                max-width: 128px;

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    img {
                        filter: var(--svg-filter-primary-color);
                    }

                    p,
                    a {
                        color: var(--primary-color);
                    }
                }

                a {
                    color: var(--text-color);
                    padding-left: 20px;
                }

                p {
                    color: var(--text-color);
                    padding-left: 20px;
                }
            }
        }

        .notifications-dropdown-menu {
            background-color: white;

            &--header {
                display: flex;
                padding: 20px;
                border-bottom: 1px solid white;

                &--logo {
                    filter: invert(0.4) sepia(1) saturate(45) hue-rotate(175deg);
                    padding-right: 15px;
                }

                &--title {
                }

                &--link {
                    margin-left: auto;
                }
            }

            &--body {
                padding: 20px;
            }
        }
    }
}
</style>